import { GetStaticProps } from 'next';
import Head from 'next/head';
import * as React from 'react';

import { Newsletter } from '../components/Newsletter/lazy';
import { VerticalPost } from '../components/Post/lazy';
import { getSortedPostsData } from '../lib/posts';
import { PostEntity } from '../types';
import { titleBuilder } from '../utils/titleBuilder';

const FourZeroFour = ({ posts }: { posts: PostEntity[] }): JSX.Element => (
    <>
        <Head>
            <title>{titleBuilder('404 Página Não Encontrada')}</title>
        </Head>
        <div className={'page-main'}>
            <section className={'page-container max-w-screen-xl'}>
                <h1 className={'page-title text-center my-20'}>404 - Página Não Encontrada</h1>
                <div className={'mt-16 flex flex-col'}>
                    <Newsletter />
                    <div className={'mt-16 flex flex-col mb-16'}>
                        <h2
                            className={
                                'm-0 font-bold uppercase tracking-wider text-green-main uppercase mb-6 text-lg text-center'
                            }>
                            Últimas postagens
                        </h2>
                        <ul className={'videos-list'}>
                            {posts.slice(0, 4).map((post) => (
                                <VerticalPost {...post} key={post.id} />
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    </>
);
export default FourZeroFour;

export const getStaticProps: GetStaticProps = async (): Promise<{
    props: { posts: PostEntity[] };
}> => ({
    props: {
        posts: getSortedPostsData()
    }
});
